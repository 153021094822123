
import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import HotelCatalogService, { HotelCatalogServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog.service';
import { ExtendedCompetitorInterface } from '@/modules/compsets/interfaces';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import CompsetSettingsService, { CompsetSettingsServiceS } from '@/modules/compsets/compset-settings.service';

import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import ScoreRating from '@/modules/common/components/ui-kit/score-rating.vue';
import Stars from '@/modules/common/components/ui-kit/stars.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import HotelCatalogSearch from '@/modules/hotels/modules/hotel-catalog/components/hotel-catalog-search.vue';
import GoogleMap from '@/modules/common/components/map.vue';
import OpenStreetMap from '@/modules/common/components/osmap.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import Slider from '@/modules/common/components/ui-kit/slider.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import CompsetTitle from './compset-title.vue';
import CompsetHotel from './compset-hotel.vue';
import WarningDistancePopup from './warning-distance.popup.vue';

// from docs https://developers.google.com/maps/documentation/javascript/using-typescript#module_import
// eslint-disable-next-line import/no-unresolved
import {} from 'googlemaps';
import CompsetModel from '../../models/compset.model';

const mapMyHotelIcon = require('@/modules/common/assets/map_my_hotel.png');
const mapSelectedMarker = require('@/modules/common/assets/map_selected_marker.png');
const mapMarker = require('@/modules/common/assets/map_marker.png');
const mapMarkerForCenter = require('@/modules/common/assets/map_marker_center.png');

const WARNING_DISTANCE = 15;

declare global {
    interface Window {
        initMap: Function;
    }
}

@Component({
    components: {
        CustomSelect,
        Stars,
        ScoreRating,
        CustomCheckbox,
        HotelCatalogSearch,
        ModalWrapper,
        CompsetTitle,
        CompsetHotel,
        CustomLoader,
        Google: GoogleMap,
        OpenStreetMap,
        Slider,
        WarningDistancePopup,
        CustomTooltip,
    },
})
export default class CompsetSettings extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(CompsetSettingsServiceS) private compsetSettingsService!: CompsetSettingsService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HotelCatalogServiceS) hotelCatalogService!: HotelCatalogService;

    isUpdating: boolean = false;
    isMapReady = false;
    isWarning = false;
    minRange = 0;
    maxRange = 100;

    selectedCompsetId: string | null = null;
    errorText: string = '';
    clickedCompset: number | null = null;
    comparisonType: COMPSET_TYPE | null = null;
    compDistance: number | null = null;
    hotelDragging: number | null = null;
    draggingOver: number | null = null;

    errors: Error[] = [];
    mapMarkers: ExtendedCompetitorInterface[] = [];

    centerOfTheMap: { lat: number; lng: number; } = {
        lat: 0,
        lng: 0,
    };

    latLngA!: google.maps.LatLng;
    latLngB!: google.maps.LatLng;

    rangeOptions = [{
        name: '10',
        value: 10,
    }, {
        name: '100',
        value: 100,
    }, {
        name: '1000',
        value: 1000,
    }];

    range = [0, this.maxRange];

    async mounted() {
        this.compsetsService.resetUpdatedCompsets();
    }

    get currentCompset() {
        if (!this.compsets) {
            return null;
        }

        if (!this.selectedCompsetId) {
            this.selectedCompsetId = this.compsets[0].id;
            return this.compsets[0];
        }

        const compset = this.compsets.find(c => c.id === this.selectedCompsetId);

        if (!compset) {
            this.selectedCompsetId = this.compsets[0].id;
            return this.compsets[0];
        }

        return compset;
    }

    get mapProvider() {
        return this.settingsGeneralService.defaultFilters.mapProvider;
    }

    get compsetType() {
        if (!this.currentCompset) {
            return null;
        }

        const compset = this.compsetsService.getLocalCompset(this.currentCompset);

        if (!compset) {
            return null;
        }

        return compset.type;
    }

    set compsetType(type: COMPSET_TYPE | null) {
        if (!this.currentCompset || type === null) {
            return;
        }

        this.compsetsService.setUpdatedCompsetType(this.currentCompset, type);
    }

    get mapSettingsRoute() {
        return {
            name: 'settings',
            params: {
                hotelId: this.$route.params.hotelId || '',
            },
            query: {
                ...this.$route.query,
                tab: 5,
                mode: 'general',
            },
        };
    }

    get settingErrors() {
        const lastError = this.errors.length - 1;
        return lastError !== -1 ? this.errors[lastError] : null;
    }

    get isUpdateButtonDisabled() {
        return this.isUpdating || !this.settingsIsChanged;
    }

    get comparisonTypes() {
        return $enum(COMPSET_TYPE).map(value => ({
            value,
            name: this.$t(`compset.${value}`) as string,
        }));
    }

    get compsets() {
        return this.compsetSettingsService.storeState.localCompsets;
    }

    get competitors() {
        return this.compsetSettingsService.getCompetitors(this.currentCompset);
    }

    get hotelsToMap() {
        const comp = this.competitors;
        const myHotel = this.compsetSettingsService.myHotelOnMap;
        if (comp && myHotel) {
            this.mapMarkers = [...comp, myHotel];
        }
        return this.collectMarkers();
    }

    get settingsIsChanged() {
        return JSON.stringify(this.compsetSettingsService.storeState.compsets)
            !== JSON.stringify(this.compsetSettingsService.storeState.localCompsets);
    }

    handleCompsetSelect(compset: CompsetModel) {
        if (this.selectedCompsetId === compset.id) {
            return;
        }

        this.selectedCompsetId = compset.id;
        this.compsetsService.resetUpdatedCompsets();
    }

    calculateDistance(from: google.maps.LatLngLiteral) {
        const myHotel = this.compsetSettingsService.myHotelOnMap;

        if (!myHotel || !this.isMapReady) {
            return null;
        }

        const { map } = this.$refs as { map: OpenStreetMap | GoogleMap };
        const distance = map.calculateDistance(from, myHotel.geoLocation);

        return Number((distance / 1000).toFixed(2));
    }

    get competitorsWithDistance() {
        const competitors: ExtendedCompetitorInterface[] = this.competitors
            .map(item => ({
                ...item,
                distance: this.calculateDistance(item.geoLocation),
            }))
            .filter(this.applyDistanceRange);
        return competitors;
    }

    applyDistanceRange(competitor: ExtendedCompetitorInterface) {
        if (!competitor.distance) {
            return false;
        }

        if (
            competitor.distance >= this.range[0]
            && competitor.distance <= this.range[1]
        ) {
            return true;
        }

        if (this.range[1] === this.maxRange && competitor.distance >= this.maxRange) {
            return true;
        }

        if (this.range[0] === this.minRange && competitor.distance <= this.minRange) {
            return true;
        }

        return false;
    }

    handleRangeChange(v: [number, number]) {
        this.range = v;
    }

    handleHotelDrag(id: number | null) {
        this.hotelDragging = id;

        if (id === null) {
            this.draggingOver = null;
        }
    }

    handleHotelHover(id: number | null) {
        if (!this.hotelDragging) {
            return;
        }

        this.draggingOver = id;

        if (this.draggingOver && this.hotelDragging) {
            this.compsetSettingsService.reorderLocalCompetitors(this.currentCompset, [this.hotelDragging, this.draggingOver]);
        }
    }

    collectMarkers() {
        const markers: google.maps.MarkerOptions[] = [];
        let mapIcon = mapSelectedMarker;
        const mapCenter = this.centerOfTheMap;
        let zIndexMarker: number = 1;

        this.mapMarkers.forEach(item => {
            if (item.isMyHotel && item.geoLocation) {
                mapIcon = mapMyHotelIcon;
                if (_.isEqual(mapCenter, { lat: 0, lng: 0 })) {
                    this.setMapCenter(item.geoLocation);
                }
            } else if (item.geoLocation === mapCenter && !item.isMyHotel) {
                mapIcon = mapMarkerForCenter;
                zIndexMarker = 2;
            } else {
                mapIcon = mapSelectedMarker;
                zIndexMarker = 1;
            }
            if (!item.isVisible && !item.isMyHotel) {
                mapIcon = mapMarker;
            }

            if (item.isMyHotel) {
                const marker = {
                    position: item.geoLocation,
                    icon: mapIcon,
                };
                markers.push(marker);
            } else {
                const marker = {
                    position: item.geoLocation,
                    icon: mapIcon,
                    tooltip: this.createTooltip(item),
                    zIndex: zIndexMarker,
                };
                markers.push(marker);
            }
        });

        return markers;
    }

    setMapCenter(location: google.maps.LatLngLiteral, competitor?: ExtendedCompetitorInterface) {
        this.centerOfTheMap = location;
        if (competitor) {
            this.clickedCompset = competitor.id;
        }
    }

    changeVisibility(competitor: ExtendedCompetitorInterface) {
        this.clearErrors();
        const errors: Error[] = [];
        const { localCompsets } = this.compsetsService;

        if (!localCompsets || !this.currentCompset || !this.compsets) {
            return;
        }

        const activeCompset = this.currentCompset;

        if (!competitor.isVisible) {
            if (activeCompset && activeCompset.competitors.length + 1 > 10) {
                errors.push(new Error('Please select no more than 10 hotels per one compset'));
            }

            const competitorsSet = new Set(
                this.compsets
                    .reduce((competitors: number[], compset) => competitors.concat(compset.competitors), [])
                    .concat([competitor.id]),
            );

            const totalUniqCompetitors = Array.from(competitorsSet);

            if (totalUniqCompetitors.length > 15) {
                errors.push(new Error('Please select no more than 15 unique hotels for all compsets'));
            }

            if (errors.length) {
                this.errors = this.errors.concat(errors);
                return;
            }
        }

        if (!competitor.isVisible
            && competitor.distance
            && competitor.distance >= WARNING_DISTANCE
            && !errors.length
        ) {
            this.distanceWarning(competitor.distance);
        }

        this.compsetSettingsService.changeVisibility(this.currentCompset, competitor.id);

        const updatedErrors = this.compsetsService.validateUpdatedCompsets(this.currentCompset);
        if (updatedErrors.length > 0) {
            this.errors = this.errors.concat(updatedErrors);
        }
    }

    async handleUpdate() {
        this.isUpdating = true;
        this.errors = await this.compsetsService.updateCompset(this.currentCompset);
        this.isUpdating = false;
    }

    distanceWarning(distance: number) {
        this.isWarning = true;
        this.compDistance = distance;
    }

    addError(err: Error) {
        this.errors.push(err);
    }

    clearErrors() {
        this.errors = [];
    }

    clearSearchedItems() {
        if (this.hotelCatalogService.data) {
            this.hotelCatalogService.data.length = 0;
        }
    }

    createTooltip(item: any) {
        const distance = this.calculateDistance(item.geoLocation);
        return `<div class="map-tooltip">
        <div class="map-tooltip__block">
            <div class="map-tooltip__block-left">
                <div class="map-tooltip__add">
                    <i data-selected="${item.isVisible}" class="icon-Icons_cug"></i>
                    <i data-selected="${!item.isVisible}" class="icon-calander-tab-1-01"></i>
                </div>
            </div>
            <div class="map-tooltip__title">${item.name}</div>
        </div>
        <div class="map-tooltip__block">
            <div class="map-tooltip__block-left">
                <div class="map-tooltip__markers">
                    <img height="24" data-selected="${item.isVisible}" src="${mapSelectedMarker}" />
                    <img height="24" data-selected="${!item.isVisible}" src="${mapMarker}" />
                </div>
            </div>
            <div class="map-tooltip__adress"></div>
            <div class="map-tooltip__distance">${this.$t('settings.compset.mapDistanceMsg', [distance || 0])}</div>
        </div>
        </div>`;
    }
}
