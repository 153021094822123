
import { Component } from 'vue-property-decorator';
import { Input } from 'element-ui';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import CompsetModel from '@/modules/compsets/models/compset.model';
import { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import { SettingsTab, TabSolver } from '../../interfaces/settings-tab.abstract';
import RoomMappingGroup, { UpdateRMSPayload } from '../room-mapping-group.vue';

@Component({
    components: {
        ProviderCard,
        RoomMappingGroup,
        Dialog,
        ElInput: Input,
        CustomSwitch,
        CustomMultiSelect,
        CustomSelect,
    },
})
export default class RoomTypeMappingTab extends SettingsTab {
    @inject(RoomsTypeManagerServiceS) private roomsTypeManagerService!: RoomsTypeManagerService;
    @inject(RoomTypesServiceS) private roomTypeService!: RoomTypesService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    static title = 'settings.roomMapping.title';
    static isFullwidth = true;
    static showSolver: TabSolver = function showSolver() {
        return !!this.$route.params.hotelId;
    };

    isPending = false;
    isArchiveMode = false;

    roomToDelete: number | null = null;

    /**
     * -1 - create new room popup
     */
    roomToRename: number | null = null;
    roomName = '';

    targetRoomTypeToMove: number | null = null;
    providerFilter: { name: string; value: string }[] = [];

    roomsRMSStates: Record<number, number[]> = {};
    private providersInitialized = false;
    private roomsRMSInitialized = false;

    beforeMount() {
        this.roomsTypeManagerService.reset();
        this.loadRoomsRMSState();
    }

    get hotels() {
        const { hotels } = this.roomsTypeManagerService;
        const { competitors, currentCompset } = this.compsetsService;
        const list = hotels || [];

        if (!currentCompset) {
            return list;
        }

        if (list.length && !this.roomsRMSInitialized) {
            this.roomsRMSInitialized = true;
            this.loadRoomsRMSState();
        }

        // hotel id is actually a string here, error in types
        return list
            .filter(h => Number(h.id) === this.mainHotelId || competitors?.includes(Number(h.id)))
            .sort(h => (Number(h.id) === this.mainHotelId ? -1 : 1));
    }

    get providers() {
        const items = this.roomsTypeManagerService.providers
            .map(provider => ({
                value: provider,
                name: this.providersService.getProviderLabel(provider),
            })) as { name: string, value: any }[];

        this.initializeProviderFilter(items);
        return items;
    }

    get isDocumentChanged() {
        return this.roomsTypeManagerService.isDocumentChanged;
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    get mainHotelId() {
        return this.compsetsService.currentCompset?.ownerHotelId || Number(this.$route.params.hotelId);
    }

    get isRoomNameTooBig() {
        return this.roomName.length > 20;
    }

    private get isRoomNameTooShort() {
        return this.roomName.length > 0 && this.roomName.length < 2;
    }

    get isRoomNameChanged() {
        if (this.roomToRename === -1) return true;

        const { realRooms } = this.roomTypeService;
        const room = realRooms
            ? realRooms.find(r => r.id === this.roomToRename)
            : null;

        const refRoomName = room ? room.name : '';
        return this.roomName !== refRoomName;
    }

    get isRoomNameExists() {
        const { realRooms } = this.roomTypeService;
        if (!realRooms) return false;

        return realRooms
            .some(r => r.name.toLowerCase().trim() === this.roomName.toLowerCase().trim() && r.id !== this.roomToRename);
    }

    get creationFormErrorMessage() {
        if (this.isRoomNameTooShort) return this.$tc('settings.roomMapping.validation.min2Chars');
        if (this.isRoomNameTooBig) return this.$tc('settings.roomMapping.validation.max20Chars');
        if (this.isRoomNameExists) return this.$tc('settings.roomMapping.validation.roomExists');

        return '';
    }

    get availableRoomsToMove() {
        const { realRooms } = this.roomTypeService;
        if (!realRooms) return [];

        return realRooms
            .filter(r => r.id !== this.roomToDelete)
            .map(r => ({
                value: r.id,
                name: r.name,
            }));
    }

    get compsetId() {
        return this.documentFiltersService.compsetId;
    }

    set compsetId(value) {
        if (value) {
            this.documentFiltersService.updateCompset(value);
        }
    }

    get compsetTypeItems(): Item[] {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return [];
        }

        return compsets
            .filter(compset => compset.ownerHotelId === Number(this.$route.params.hotelId))
            .map((compset: CompsetModel) => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
            }));
    }

    onRoomTypeDelete(roomTypeId: number) {
        this.roomToDelete = roomTypeId;
        this.targetRoomTypeToMove = null;
    }

    onRoomTypeRename(roomTypeId: number) {
        this.roomToRename = roomTypeId;
        this.roomName = this.getRoomTypeLabel(roomTypeId);
    }

    async createRoom() {
        this.roomToRename = -1;
        this.roomName = '';
    }

    async deleteRoom() {
        const { roomToDelete, targetRoomTypeToMove } = this;
        const isBusy = this.roomsTypeManagerService
            .isRoomTypeHaveRooms(roomToDelete!);

        try {
            this.isPending = true;
            this.roomToDelete = null;
            this.targetRoomTypeToMove = null;

            if (isBusy) {
                await this.roomsTypeManagerService
                    .moveAllRoomsToRoomType(this.mainHotelId, roomToDelete!, targetRoomTypeToMove!);
            }

            // NOTE: Wait until the BE apply the changes and then call another request
            //       because sometimes the BE thinks that there are assigned rooms to
            //       the room type that should be removed (even if we already moved
            //       rooms to another room type)
            await new Promise(resolve => { setTimeout(resolve, 100); });

            await this.roomTypeService.deleteRoom(roomToDelete!);
            await this.loadRoomsRMSState();
        } finally {
            this.isPending = false;
        }
    }

    async renameRoom() {
        this.isPending = true;

        try {
            if (this.roomToRename !== -1) {
                await this.roomTypeService.editRoomType({ id: this.roomToRename!, name: this.roomName, displayName: this.roomName });
            } else {
                await this.roomTypeService.addRoomType(this.roomName.trim());
            }
            this.roomToRename = null;
        } finally {
            this.isPending = false;
        }
    }

    isRoomBusy(roomTypeId: number) {
        return this.roomsTypeManagerService.isRoomTypeHaveRooms(roomTypeId);
    }

    isHotelExpanded(hotelId: number) {
        const fornovaId = sessionStorage.getItem('fornovaId');

        if (!fornovaId || Number(fornovaId) !== Number(hotelId)) {
            return false;
        }

        // can't be removed immediatly, bcz calls multiple times during rendering.
        setTimeout(() => sessionStorage.removeItem('fornovaId'), 50);
        return true;
    }

    getRoomTypeLabel(roomTypeId: number) {
        if (!roomTypeId) return '';
        if (roomTypeId === -1) return 'New';

        const roomType = this.roomTypeService.getRoomType(roomTypeId);

        if (!roomType) return '';
        return roomType.name;
    }

    private async loadRoomsRMSState() {
        const states = await this.roomsTypeManagerService.getRoomsRMSState(this.mainHotelId);
        const entries = states.map(state => [state.hotelId, state.avoidedRoomTypes]);

        this.roomsRMSStates = Object.fromEntries(entries);
    }

    private async initializeProviderFilter(items: { name: string, value: string }[]) {
        if (this.providersInitialized) return;
        if (!items.length) return;

        this.providerFilter = [...items];
        this.providersInitialized = true;
    }

    getHotelRmsState(hotelId: number) {
        if (!this.roomsRMSStates[hotelId]) {
            this.roomsRMSStates[hotelId] = [];
        }

        return this.roomsRMSStates[hotelId];
    }

    async handleRmsUpdate({
        hotelId, roomTypeId, entireCompset, toRemove,
    }: UpdateRMSPayload) {
        const oldState = { ...this.roomsRMSStates };

        const applyState = (hid: number) => {
            this.roomsRMSStates[hid] = toRemove
                ? this.roomsRMSStates[hid].filter(id => id !== roomTypeId)
                : this.roomsRMSStates[hid].concat([roomTypeId]);
        };

        if (entireCompset) {
            (Object.keys(this.roomsRMSStates) as unknown as number[])
                .forEach(applyState);
        } else {
            applyState(hotelId);
        }

        this.isPending = true;

        const operation: keyof RoomsTypeManagerService = toRemove
            ? 'restoreRoomsRMS'
            : 'eliminateRoomsRMS';

        const rmsStatePayload = entireCompset
            ? this.hotels.map(hotelData => ({
                hotelId: +hotelData.id,
                rooms: toRemove ? [roomTypeId] : [...this.roomsRMSStates[+hotelData.id]],
            }))
            : [{
                hotelId: +hotelId,
                rooms: toRemove ? [roomTypeId] : [...this.roomsRMSStates[+hotelId]],
            }];

        try {
            await this.roomsTypeManagerService[operation](this.mainHotelId, rmsStatePayload);
        } catch (err) {
            this.roomsRMSStates = {
                ...oldState,
            };
            throw (err);
        } finally {
            this.isPending = false;
        }
    }

    handleSetPending(isPending: boolean) {
        this.isPending = isPending;
    }

    handleInitScroll(expandedPanelY: number) {
        const { mappingList } = this.$refs;
        (mappingList as HTMLElement).scrollTo(0, expandedPanelY - (mappingList as HTMLElement).getBoundingClientRect().y);
    }
}
